@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,400,400i");
@import url("https://fonts.googleapis.com/earlyaccess/sawarabimincho.css");
@keyframes log_animation {
  0% {
    margin-top: 0;
  }
  15% {
    margin-top: 10px;
  }
  35% {
    margin-top: 0;
  }
  50% {
    margin-top: 10px;
  }
  72% {
    margin-top: 0;
  }
}
@keyframes top_right {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes footer_anime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes about_anime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shop_anime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes works_anime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blog_anime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
*, *:before, *:after {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  padding: 0;
  color: inherit;
  font-size: 100%;
  font: inherit;
  text-decoration: none;
  vertical-align: baseline;
  transition: 0.5s;
}

article, aside, details, dialog, figcaption, figure, footer, header, main, menu, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

body {
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-size-adjust: 100%;
}

br, hr {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

input, select {
  vertical-align: middle;
}

img {
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

/* --------------------------------------------------
 00:  Utility Style
 -------------------------------------------------- */
/* float */
.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

/* align */
.taL {
  text-align: left !important;
}

.taR {
  text-align: right !important;
}

.taC {
  text-align: center !important;
}

/* size */
.txXS {
  font-size: 10rem !important;
}

.txSS {
  font-size: 11rem !important;
}

.txS {
  font-size: 12rem !important;
}

.txM {
  font-size: 13rem !important;
}

.txL {
  font-size: 16rem !important;
}

.txLL {
  font-size: 18rem !important;
}

.txXL {
  font-size: 20rem !important;
}

/* Margin Padding */
.noMargin {
  margin: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

/* Clear */
.clr {
  clear: both;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* display */
@media only screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}
@media only screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

html {
  font-size: 100%;
  color: #333;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  background: url(../img/common/bg_mat.jpg) #e9d6b7;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-size: 14px;
  line-height: 1.6;
}
@media only screen and (max-width: 768px) {
  body {
    font-size: 4.1vw;
    background: url(../img/common/bg.jpg) #e9d6b7;
  }
}
body.resizer *, body.resizer *:after, body.resizer *:before {
  transition: none !important;
}

input,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border-radius: 0;
  border: 0;
  font-size: 100%;
  vertical-align: middle;
}

select {
  background-color: transparent;
  text-indent: .01px;
  text-overflow: "";
}

textarea {
  font-size: 100%;
}

em {
  font-style: normal;
}

strong {
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
img[src*="_sp."] {
  display: none;
}
@media only screen and (max-width: 768px) {
  img[src*="_pc."] {
    display: none;
  }
  img[src*="_sp."] {
    display: inline;
  }
}

#wrap {
  overflow: hidden;
}

.loading header nav li {
  transform: translate(-50px, 0);
}
.loading main:after {
  width: 100%;
}
.loading main #first {
  width: 100%;
}
.loading main #first h1 {
  transform: translate(0, -50%);
  top: 47%;
  left: 0;
  right: 0;
  animation: log_animation 2s infinite;
}
@media only screen and (max-width: 768px) {
  .loading main #first h1 {
    animation: none;
    top: 0;
    transform: translate(0, 0);
  }
}
.loading main #first h2 {
  opacity: 0;
  transform: translate(0, 30px);
}
.loading main #first p {
  opacity: 0;
  transform: translate(0, 30px);
}
.loading main #top_right {
  opacity: 0;
}
.loading main #top_right ul {
  transform: translate(0, -50%);
}
@media only screen and (max-width: 768px) {
  .loading main #top_right ul {
    transform: translate(0, 0);
  }
}
.loading main #top_right li {
  filter: grayscale(1);
}
.loading main + footer {
  opacity: 0;
}

.loaded nav ul li:nth-child(1) {
  transition-delay: 0s;
}
.loaded nav ul li:nth-child(2) {
  transition-delay: 0s;
}
.loaded nav ul li:nth-child(3) {
  transition-delay: 0s;
}
.loaded nav ul li:nth-child(4) {
  transition-delay: 0s;
}
.loaded nav ul li:nth-child(5) {
  transition-delay: 0s;
}
.loaded main:after {
  transition-delay: 0s;
}
.loaded #first * {
  transition-delay: 0s;
}
.loaded #first .discription, .loaded #first .prof {
  transition-delay: 0s;
}
.loaded footer {
  transition-delay: 0s;
}

main {
  position: relative;
}
main:before {
  content: 'ページを読み込み中です...';
  color: #777;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 45px;
  z-index: -1;
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  main:before {
    margin-left: 0;
  }
}
main:after {
  content: '';
  background: url(../img/common/bg.jpg) #e9d6b7;
  position: fixed;
  width: 50%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.5s;
  box-shadow: 0px 0px 4px 0px #666;
  transition: 0.8s cubic-bezier(0.65, 0.01, 0.11, 1.01);
  transition-delay: 1.3s;
}
@media only screen and (max-width: 768px) {
  main:after {
    display: none;
  }
}
main .nav_logo {
  border: 1px solid #6d8d93;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: absolute;
  right: -18px;
  top: -20px;
  text-align: center;
  transition: 1s;
  transition-delay: 1s;
}
@media only screen and (max-width: 768px) {
  main .nav_logo {
    position: absolute;
    width: 120px;
    height: 120px;
    right: auto;
    left: -13px;
  }
}
main .nav_logo a {
  display: block;
  position: relative;
  z-index: 1;
}
main .nav_logo img {
  padding-top: 20px;
  width: 100px;
}
@media only screen and (max-width: 768px) {
  main .nav_logo img {
    width: 85px;
    padding-top: 17px;
  }
}
main .nav_logo:after {
  content: '';
  width: 134px;
  height: 134px;
  border: 1px solid #6d8d93;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  opacity: 0.5;
}
@media only screen and (max-width: 768px) {
  main .nav_logo:after {
    width: 113px;
    height: 113px;
    display: block;
    z-index: 0;
  }
}

.lower {
  line-height: 2;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .lower {
    background: url(../img/common/bg_mat.jpg) #d9cbaf;
    font-size: 4vw;
  }
}
.lower main {
  padding: 75px 60px 0 144px;
  position: relative;
  z-index: 1;
  transition-delay: 0.8s;
  transition-duration: 0s;
  margin: 0 auto;
}
.lower main p {
  font-size: 14px;
  font-size: 1.36719vw;
}
@media only screen and (max-width: 768px) {
  .lower main p {
    font-size: 4.2vw;
  }
}
.lower main:after {
  width: 84px;
}
@media only screen and (max-width: 768px) {
  .lower main {
    width: 100%;
    padding: 0px 0px 20px;
  }
  .lower main:after {
    display: none;
  }
}
.lower footer {
  padding: 0px 60px 20px 144px;
}
@media only screen and (max-width: 768px) {
  .lower footer {
    padding: 0px 0px 20px 0px;
    margin: 0 20px;
  }
  .lower footer .sitemap {
    width: 75%;
  }
}

.single_inner {
  max-width: 1000px;
  margin: 0 auto;
}

a {
  color: #1b1922;
  text-decoration: underline;
  transition: .25s;
}
a:hover {
  text-decoration: none;
}

header {
  position: fixed;
  width: 84px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;
}
header .sns {
  display: none;
}
@media only screen and (max-width: 768px) {
  header {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 0;
    transform: translate(0, 0);
  }
  header .inner {
    height: 0;
  }
  header .sns {
    display: block;
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .nav_wrap {
    overflow-y: scroll;
    background: #fff;
    padding: 10px 0 30px;
    width: 94%;
    margin: 3% auto 0;
    top: 4vw;
    height: 90vh;
    -webkit-overflow-scrolling: touch;
    transform: translate(150%, 0) rotate(40deg);
    transition: 0.8s cubic-bezier(0.58, 0.01, 0.19, 1);
  }
  .nav_wrap.on {
    transform: translate(0, 0) rotate(0deg);
  }
}

nav ul li {
  display: block;
  width: 84px;
  height: 120px;
  transition: 0.3s cubic-bezier(0.54, 0.03, 0.13, 0.99);
}
@media only screen and (max-width: 768px) {
  nav ul li {
    height: auto;
    width: auto;
    margin: 22px 0;
  }
}
nav ul li:first-child {
  height: 80px;
}
nav ul li:nth-child(1) {
  transition-delay: 1s;
}
nav ul li:nth-child(2) {
  transition-delay: 1.07s;
}
nav ul li:nth-child(3) {
  transition-delay: 1.14s;
}
nav ul li:nth-child(4) {
  transition-delay: 1.21s;
}
nav ul li:nth-child(5) {
  transition-delay: 1.28s;
}
nav ul li a {
  display: block;
  letter-spacing: 3px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 18px;
  font-style: italic;
  color: #1b1922;
  text-decoration: none;
  position: relative;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%) rotate(90deg);
  transition: 0.3s cubic-bezier(0.54, 0.03, 0.13, 0.99);
}
nav ul li a:hover {
  color: #fff;
}
@media only screen and (max-width: 768px) {
  nav ul li a:hover {
    color: #1b1922;
  }
}
@media only screen and (max-width: 768px) {
  nav ul li a {
    text-align: center;
    top: 0;
    left: 0;
    font-size: 10vw;
    line-height: 1.6;
    transform: translate(0, 0) rotate(0deg);
  }
}

.lower header {
  transition-delay: 0s;
}
.lower main:after {
  transition-delay: 0s;
}
.lower nav ul li {
  height: 90px;
  transition-delay: 0s;
  transform: translate(0, 0);
}
.lower nav ul li.nav_top {
  height: 80px;
}
@media only screen and (max-width: 768px) {
  .lower nav ul li {
    height: auto;
  }
  .lower nav ul li.nav_top {
    height: auto;
  }
}

#top .nav_logo {
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  #top li.nav_top {
    height: auto;
  }
}

#about li.nav_about {
  height: 315px;
  margin-left: 35px;
}
@media only screen and (max-width: 768px) {
  #about li.nav_about {
    height: auto;
    margin: 22px 0;
  }
}
#about li.nav_about a {
  font-weight: 100;
  font-size: 115px;
  top: 13%;
  letter-spacing: -0.05em;
}
@media only screen and (max-width: 768px) {
  #about li.nav_about a {
    letter-spacing: 3px;
    top: 0;
    font-size: 10vw;
    font-weight: 200;
  }
}

#shop li.nav_shop {
  height: 300px;
  margin-left: 35px;
}
@media only screen and (max-width: 768px) {
  #shop li.nav_shop {
    height: auto;
    margin: 22px 0;
  }
}
#shop li.nav_shop a {
  font-weight: 100;
  font-size: 115px;
  top: 18%;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 768px) {
  #shop li.nav_shop a {
    letter-spacing: 3px;
    top: 0;
    font-size: 10vw;
    font-weight: 200;
  }
}

#works li.nav_works {
  height: 315px;
  margin-left: 35px;
}
@media only screen and (max-width: 768px) {
  #works li.nav_works {
    height: auto;
    margin: 22px 0;
  }
}
#works li.nav_works a {
  font-weight: 100;
  font-size: 115px;
  top: 11%;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 768px) {
  #works li.nav_works a {
    letter-spacing: 3px;
    top: 0;
    font-size: 10vw;
    font-weight: 200;
  }
}

#blog li.nav_blog {
  height: 300px;
  margin-left: 35px;
}
@media only screen and (max-width: 768px) {
  #blog li.nav_blog {
    height: auto;
    margin: 22px 0;
  }
}
#blog li.nav_blog a {
  font-weight: 100;
  font-size: 115px;
  top: 16%;
}
@media only screen and (max-width: 768px) {
  #blog li.nav_blog a {
    top: 0;
    font-size: 10vw;
    font-weight: 200;
  }
}

.btn-menu {
  z-index: 20000;
  position: absolute;
  top: 0px;
  right: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: none;
  transition: .25s;
}
.btn-menu > span {
  position: absolute;
  left: 0;
  right: 0;
  width: 24px;
  height: 1px;
  margin: 0 auto;
  background: #000;
  transition: .25s;
}
.btn-menu > span:nth-child(1) {
  top: 22px;
}
.btn-menu > span:nth-child(2) {
  top: 27px;
  width: 18px;
  margin: 0;
  left: auto;
  right: 18.5px;
}
.btn-menu > span:nth-child(3) {
  top: 32px;
}
.btn-menu > span:nth-child(4) {
  top: 37px;
  width: 18px;
  margin: 0;
  left: auto;
  right: 18.5px;
}
.btn-menu > span:nth-child(5) {
  top: 42px;
  width: 12px;
  margin: 0;
  left: auto;
  right: 18.5px;
}
.btn-menu.on span:nth-child(1) {
  background: #a0a09d;
  top: 30px;
  transform: rotate(45deg);
}
.btn-menu.on span:nth-child(2) {
  opacity: 0;
  transform: rotate(90deg);
}
.btn-menu.on span:nth-child(3) {
  top: 30px;
  background: #a0a09d;
  transform: rotate(135deg);
}
.btn-menu.on span:nth-child(4) {
  opacity: 0;
}
.btn-menu.on span:nth-child(5) {
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  .btn-menu {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  section .inner {
    width: 100%;
  }
}

.lower #contact {
  line-height: 1.6;
}
@media only screen and (max-width: 768px) {
  .lower #contact {
    width: 94%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

#contact {
  width: 768px;
  padding: 0 30px 40px;
  background: #fff;
  position: fixed;
  left: 50%;
  margin-left: -345px;
  z-index: 4;
  bottom: 0px;
  transform: translate(0, 100%);
  transition: 0.6s cubic-bezier(0.58, 0.01, 0.19, 1);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 768px) {
  #contact {
    width: 94%;
    height: 95vh;
    overflow: scroll;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 20px 30px;
    -webkit-overflow-scrolling: touch;
  }
}
#contact.open {
  bottom: 55%;
  transform: translate(0, 50%);
}
@media only screen and (max-width: 768px) {
  #contact.open {
    top: 10px;
    transform: translate(0, 0);
    bottom: auto;
  }
}
#contact .form_left {
  float: left;
  width: 47.8%;
}
@media only screen and (max-width: 768px) {
  #contact .form_left {
    width: 100%;
    float: none;
  }
}
#contact .form_left p {
  margin-bottom: 55px;
  line-height: 1.9;
}
@media only screen and (max-width: 768px) {
  #contact .form_left p {
    margin-bottom: 25px;
  }
}
#contact .form_right {
  float: right;
  width: 47.8%;
}
@media only screen and (max-width: 768px) {
  #contact .form_right {
    width: 100%;
    float: none;
  }
}
#contact h4 {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  text-align: center;
  padding-top: 55px;
  margin: 0 0 40px 0;
  position: relative;
}
#contact h4:before {
  content: '';
  width: 17px;
  height: 1px;
  background: #a2957a;
  top: 30px;
  position: absolute;
  right: 0;
  left: auto;
  transform: rotate(45deg);
}
#contact h4:after {
  content: '';
  width: 17px;
  height: 1px;
  background: #a2957a;
  top: 30px;
  position: absolute;
  right: 0;
  left: auto;
  transform: rotate(-45deg);
}
@media only screen and (max-width: 768px) {
  #contact h4 {
    margin: 0 0 20px;
    padding-top: 40px;
  }
  #contact h4:before {
    left: 0;
    right: auto;
  }
  #contact h4:after {
    left: 0;
    right: auto;
  }
}
#contact dl {
  margin-bottom: 1.5em;
}
#contact dt {
  margin-bottom: 5px;
}
#contact input, #contact textarea {
  background: #f7f6f2;
  width: 100%;
  padding: 5px 10px;
  font-size: 16px;
}
#contact textarea {
  height: 168px;
}
#contact hr {
  clear: both;
  border: none;
  margin-bottom: 20px;
}
#contact .submit_btn {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  width: 200px;
}
#contact div.wpcf7-response-output {
  margin: 0 !important;
}

#pagetop {
  display: block;
  position: fixed;
  width: 150px;
  padding: 15px;
  border: 1px solid #000;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  right: 50px;
  bottom: 50px;
  transition: .25s;
}
#pagetop:hover {
  color: #c00;
}
@media only screen and (max-width: 768px) {
  #pagetop {
    width: 70px;
    font-size: 12px;
    right: 20px;
    bottom: 20px;
    padding: 10px;
  }
}

#top footer {
  position: fixed;
  width: 50%;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 0 0 15px;
}
#top footer .inner {
  border: none;
}
@media only screen and (max-width: 768px) {
  #top footer {
    padding: 0;
    position: relative;
    width: 100%;
  }
}

footer {
  color: #fff;
  padding-bottom: 20px;
  position: relative;
  z-index: 2;
  transition-delay: 1.4s;
}
footer .inner {
  border-top: 1px dotted #977;
  margin: 60px auto 0 auto;
  padding-top: 40px;
}
footer .copy {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 12px;
  color: #9e907e;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  footer .copy {
    padding-bottom: 20px;
  }
}
footer .sitemap {
  display: none;
}
footer .sns {
  text-align: center;
}
@media only screen and (max-width: 768px) {
  footer .inner {
    margin-top: 0;
  }
  footer .sitemap {
    display: block;
    text-align: center;
    width: 65%;
    margin: 0 auto 15px;
  }
  footer .sitemap li {
    display: inline-block;
    margin: 0 12px 15px;
  }
  footer .sitemap a {
    color: #1b1922;
    display: inline-block;
    text-decoration: none;
    font-size: 4.5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-style: italic;
    letter-spacing: 1px;
  }
}

.sns {
  padding-bottom: 1vw;
}
@media only screen and (max-width: 768px) {
  .sns {
    padding-bottom: 30px;
  }
}
.sns ul {
  overflow: hidden;
}
.sns li {
  display: inline-block;
  margin: 0 5px;
  vertical-align: top;
}
@media only screen and (max-width: 768px) {
  .sns li {
    margin: 0 10px;
  }
}
.sns a {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #a2957a;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .sns a {
    width: 40px;
    height: 40px;
  }
}
.sns svg {
  position: relative;
  width: 22px;
  height: 22px;
  top: 50%;
  transform: translate(0, -51%);
  left: 1px;
}
@media only screen and (max-width: 768px) {
  .sns svg {
    width: 24px;
    height: 24px;
  }
}
.sns path {
  fill: #a2957a;
}
.sns .fb svg {
  left: 0px;
}
.sns .fb:hover {
  border-color: #315096;
}
.sns .fb:hover path {
  fill: #315096;
}
.sns .tw:hover {
  border-color: #55acee;
}
.sns .tw:hover path {
  fill: #55acee;
}
.sns .mail {
  position: relative;
  cursor: pointer;
}
.sns .mail:before {
  content: '';
  width: 17px;
  height: 1px;
  background: #a2957a;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(0deg);
  opacity: 0;
  transition: 0.5s;
}
.sns .mail:after {
  content: '';
  width: 17px;
  height: 1px;
  background: #a2957a;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: rotate(0deg);
  opacity: 0;
  transition: 0.5s;
}
.sns .mail:hover {
  border-color: #fff;
}
.sns .mail:hover path {
  fill: #fff;
}
.sns .mail svg {
  width: 18px;
  left: 0;
  transition: 0.5s;
  transform: rotate(0deg) translate(0, -51%);
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .sns .mail svg {
    width: 20px;
    height: 20px;
  }
}
.sns .mail.on {
  border-color: #fff;
}
.sns .mail.on:before {
  opacity: 1;
  transform: rotate(45deg);
  background: #fff;
}
.sns .mail.on:after {
  opacity: 1;
  transform: rotate(-45deg);
  background: #fff;
}
.sns .mail.on path {
  fill: #fff;
}
.sns .mail.on svg {
  transform: rotateX(90deg);
  opacity: 0;
}

/* animation */
@keyframes twincle {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.btn {
  width: 150px;
}
.btn--small {
  height: 50px;
  font-size: 12px;
}
.btn--detail {
  font-weight: bold;
}
.btn--detail::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translateY(-50%) rotate(45deg);
}
@media only screen and (max-width: 768px) {
  .btn {
    width: 60%;
  }
  .btn--small {
    height: 40px;
    font-size: 10px;
  }
}

.btn_cart {
  text-align: center;
  font-size: 16px;
  font-size: 1.5625vw;
  background: #e9dec4;
  border-radius: 5px;
  position: relative;
}
.btn_cart:before {
  content: '';
  width: 32px;
  height: 24px;
  background: url(../img/common/icn_cart.png) no-repeat center;
  background-size: 25px 41px;
  background-position: 0px 2px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0, -50%);
  z-index: 2;
  transition: 0.25s;
}
@media only screen and (max-width: 768px) {
  .btn_cart:before {
    transform: scale(0.8) translate(0, -55%);
    left: 7px;
  }
}
.btn_cart:after {
  content: '';
  background: #aec64e;
  display: block;
  width: 100%;
  top: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  transition: 0.25s;
}
.btn_cart:hover a {
  color: #fff;
}
.btn_cart:hover:after {
  top: 0;
}
.btn_cart:hover:before {
  background-position: 0px -20px;
}
@media only screen and (max-width: 768px) {
  .btn_cart {
    font-size: 4vw;
  }
}
.btn_cart a {
  position: relative;
  z-index: 1;
  display: block;
  text-decoration: none;
  padding: 5px 0 5px 20px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .btn_cart a {
    padding: 10px 10px 10px 35px;
  }
}

.headline {
  color: #1b1922;
  font-weight: bold;
}

.text {
  margin-bottom: 25px;
}
.text--lead-main {
  font-size: 18px;
  font-weight: bold;
}
.text--lead-sub {
  font-size: 16px;
  font-weight: bold;
}
.text--large {
  font-size: 18px;
}
.text--small {
  font-size: 12px;
}
@media only screen and (max-width: 768px) {
  .text {
    margin-bottom: 15px;
  }
  .text--lead-main {
    font-size: 14px;
  }
  .text--lead-sub {
    font-size: 13px;
  }
  .text--large {
    font-size: 16px;
  }
  .text--small {
    font-size: 10px;
  }
}

.line {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  border: none;
  border-top: 1px solid #000;
}
@media only screen and (max-width: 768px) {
  .line {
    margin-top: 17px;
    margin-bottom: 17px;
  }
}

.table {
  margin-top: 35px;
}
.table table {
  width: 100%;
}
.table th,
.table td {
  padding: 20px;
  vertical-align: middle;
}
.table th > *,
.table td > * {
  margin-top: 15px;
}
.table th > *:first-child,
.table td > *:first-child {
  margin-top: 0;
}
.table th {
  color: #fff;
  text-align: left;
}
.table__cell--5 {
  width: 5%;
}
.table__cell--10 {
  width: 10%;
}
.table__cell--15 {
  width: 15%;
}
.table__cell--20 {
  width: 20%;
}
.table__cell--25 {
  width: 25%;
}
.table__cell--30 {
  width: 30%;
}
.table__cell--35 {
  width: 35%;
}
.table__cell--40 {
  width: 40%;
}
.table__cell--45 {
  width: 45%;
}
.table__cell--50 {
  width: 50%;
}
.table__cell--55 {
  width: 55%;
}
.table__cell--60 {
  width: 60%;
}
.table__cell--65 {
  width: 65%;
}
.table__cell--70 {
  width: 70%;
}
.table__cell--75 {
  width: 75%;
}
.table__cell--80 {
  width: 80%;
}
.table__cell--85 {
  width: 85%;
}
.table__cell--90 {
  width: 90%;
}
.table__cell--95 {
  width: 95%;
}
.table__cell--100 {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .table {
    margin-top: 25px;
  }
  .table th,
  .table td {
    display: block;
    padding: 10px;
  }
  .table__cell {
    width: auto;
  }
}

.pagenation {
  font-size: 18px;
  font-size: 1.75781vw;
  border-bottom: 1px dotted #977;
  padding-top: 30px;
  padding-bottom: 50px;
  margin: 0 30px 50px;
}
@media only screen and (max-width: 768px) {
  .pagenation {
    font-size: 5.5vw;
    margin: 0 20px 0;
    padding: 0 0 25px;
  }
}
.pagenation ul {
  text-align: center;
}
.pagenation li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  line-height: 1;
}
@media only screen and (max-width: 768px) {
  .pagenation li {
    margin: 0 6px;
  }
}
.pagenation li:after {
  content: '';
  width: 2px;
  height: 2px;
  background: #1b1922;
  position: absolute;
  right: -15px;
  bottom: 3px;
  display: block;
  cursor: default;
}
.pagenation li:nth-child(6):after {
  display: none;
}
.pagenation .prev {
  margin-right: 40px;
}
.pagenation .prev:after {
  display: none;
}
@media only screen and (max-width: 768px) {
  .pagenation .prev {
    margin: 0 20px 0 0;
  }
}
.pagenation .next {
  margin-left: 40px;
}
@media only screen and (max-width: 768px) {
  .pagenation .next {
    margin: 0 0 0 20px;
  }
}
.pagenation .next:after {
  display: none;
}
.pagenation a, .pagenation span {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  line-height: 1;
  padding: 0 5px;
}
.pagenation a:hover {
  text-decoration: underline;
}
.pagenation span {
  color: #fff;
}
.pagenation span.dots {
  color: #1b1922;
}

.pagenation.single {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.pagenation.single .prev {
  float: left;
  margin: 0;
}
.pagenation.single .next {
  float: right;
  margin: 0;
}

.category_list {
  margin: 0 30px 30px;
}
.category_list:last-child {
  padding-bottom: 60px;
  border-bottom: 1px dotted #977;
}
@media only screen and (max-width: 768px) {
  .category_list {
    margin: 30px 20px 0;
  }
  .category_list:last-child {
    padding-bottom: 30px;
  }
}
.category_list h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  color: #1b1922;
  font-size: 18px;
  font-size: 1.75781vw;
}
@media only screen and (max-width: 768px) {
  .category_list h5 {
    font-size: 5.5vw;
  }
}
.category_list ul {
  text-align: left;
}
.category_list li {
  display: inline-block;
  margin-right: 0.8em;
  padding-right: 1.5em;
  position: relative;
}
.category_list li:after {
  content: '/';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.category_list li:last-child:after {
  display: none;
}
@media only screen and (max-width: 768px) {
  .category_list li {
    font-size: 4.4vw;
    margin-bottom: 5px;
  }
}
.category_list a {
  display: inline-block;
}

.reccomend {
  margin-bottom: 45px;
  border-bottom: 1px dotted #977;
  padding-bottom: 60px;
}
@media only screen and (max-width: 768px) {
  .reccomend {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
}
.reccomend h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  color: #1b1922;
  font-size: 18px;
  font-size: 1.75781vw;
  margin-bottom: 15px;
}
@media only screen and (max-width: 768px) {
  .reccomend h5 {
    font-size: 5.5vw;
  }
}
.reccomend ul {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .reccomend ul {
    flex-wrap: wrap;
  }
}
.reccomend li {
  width: 22%;
  margin-right: 4%;
}
.reccomend li:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 768px) {
  .reccomend li {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 25px;
  }
  .reccomend li:nth-child(2n) {
    margin-right: 0;
  }
}
.reccomend a {
  font-size: 14px;
  font-size: 1.36719vw;
  text-decoration: none;
  line-height: 1.6;
}
.reccomend img {
  margin-bottom: 10px;
}

.lower main.in:before, main.in:before {
  z-index: 5;
  opacity: 1;
  animation: twincle 3s infinite;
}
.lower main.in .barba-container #first, main.in .barba-container #first {
  transform: translate(-32vw, 0);
  transition: all 0.8s cubic-bezier(0.5, 0.03, 0.39, 0.99), opacity 0.4s cubic-bezier(0.33, 0.49, 0.25, 1.01) 0.2s;
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  .lower main.in .barba-container #first, main.in .barba-container #first {
    transform: translate(0, 0);
    opacity: 0;
  }
}
.lower main.in .barba-container #top_right li, main.in .barba-container #top_right li {
  transform: translate(70vw, 0);
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  .lower main.in .barba-container #top_right li, main.in .barba-container #top_right li {
    transform: translate(0, 0);
    opacity: 0;
  }
}
@media only screen and (max-width: 768px) {
  .lower main.in .nav_logo, main.in .nav_logo {
    opacity: 0;
    z-index: 5;
    transform: translate(0, 0, 1);
    transition-delay: 0s;
  }
}
.lower main .barba-container #first, main .barba-container #first {
  transition-delay: 0s;
}

.lower main.in .barba-container section {
  opacity: 0;
  transform: translate(-10vw, 0);
  transition-duration: 0s;
}
@media only screen and (max-width: 768px) {
  .lower main.in .barba-container section {
    transform: translate(0, 0);
    opacity: 0;
  }
}

#about main.in .barba-container section, #works main.in .barba-container section, #blog main.in .barba-container section, #shop main.in .barba-container section {
  transform: translate(-10vw, 0);
  transition: all 0.8s cubic-bezier(0.5, 0.03, 0.39, 0.99), opacity 0.4s cubic-bezier(0.33, 0.49, 0.25, 1.01) 0.2s;
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  #about main.in .barba-container section, #works main.in .barba-container section, #blog main.in .barba-container section, #shop main.in .barba-container section {
    transform: translate(0, 0);
    opacity: 0;
  }
}

footer.in {
  transform: translate(-32vw, 0);
  transition: all 0.8s cubic-bezier(0.65, 0.01, 0.11, 1.01), opacity 0.4s cubic-bezier(0.33, 0.49, 0.25, 1.01) 0.4s;
  opacity: 0;
  animation: 3s footer_anime;
}
@media only screen and (max-width: 768px) {
  footer.in {
    transform: translate(0, 0);
  }
}

#top footer.in {
  transform: translate(-29.7vw, 0);
  animation: none;
}
@media only screen and (max-width: 768px) {
  #top footer.in {
    transform: translate(0, 0);
  }
}

#first {
  position: fixed;
  width: 50%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
}
@media only screen and (max-width: 768px) {
  #first {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 45px;
  }
}
#first .inner {
  width: 50%;
  position: absolute;
  top: 47%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  margin: 0 auto;
  padding-top: 17vw;
}
@media only screen and (max-width: 768px) {
  #first .inner {
    position: relative;
    width: 100%;
    top: 0;
    transform: translate(0, 0);
    padding-top: 43px;
  }
}
#first h1 {
  display: block;
  width: 17vw;
  height: 17vw;
  border: 1px solid #6d8d93;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  transform: translate(0, 0);
  transition: 0.8s cubic-bezier(0.48, -0.57, 0.2, 0.99);
  transition-delay: 0.7s;
}
#first h1 img {
  position: relative;
  width: 11.5vw;
  top: 50%;
  transform: translate(0, -50%);
}
#first h1:after {
  content: '';
  width: 16.28vw;
  height: 16.28vw;
  top: 0.26vw;
  left: 0.26vw;
  border: 1px solid #6d8d93;
  border-radius: 50%;
  position: absolute;
  opacity: 0.5;
}
@media only screen and (max-width: 768px) {
  #first h1 {
    transition-delay: 0s;
    width: 44vw;
    height: 44vw;
    position: relative;
  }
  #first h1 img {
    width: 30vw;
  }
  #first h1:after {
    width: 41vw;
    height: 41vw;
    top: 1.2vw;
    left: 1.2vw;
  }
}
#first h2 {
  text-align: center;
  font-size: 1.3vw;
  margin: 2.5vw auto 2vw;
  line-height: 1.8;
  letter-spacing: 0.05rem;
  transition-delay: 1s;
}
@media only screen and (max-width: 768px) {
  #first h2 {
    transition-delay: 0s;
    margin: 43px auto 20px;
    font-size: 4.5vw;
    letter-spacing: 0;
  }
}
#first .discription {
  width: 86%;
  font-size: 1.1vw;
  margin: 0 auto 0.5vw auto;
  line-height: 2;
  transition-delay: 1.1s;
}
@media only screen and (max-width: 768px) {
  #first .discription {
    transition-delay: 0.1s;
    width: 100%;
    padding: 0 30px;
    font-size: 3.75vw;
    margin-bottom: 10px;
  }
}
#first .prof {
  text-align: right;
  width: 76%;
  font-size: 0.8vw;
  margin: 0 auto;
  line-height: 2;
  white-space: nowrap;
  transition-delay: 1.2s;
}
#first .prof span {
  margin-left: -20px;
}
@media only screen and (max-width: 768px) {
  #first .prof {
    transition-delay: 0.2s;
    width: 100%;
    padding: 0 35px;
    font-size: 3.4vw;
  }
  #first .prof span {
    margin-left: 0;
    margin-right: -5px;
  }
}

#top_right {
  position: relative;
  left: 49.5%;
  width: 50.5%;
  animation: 1s top_right;
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  #top_right {
    width: 100%;
    left: 0;
  }
}
#top_right img {
  width: 100%;
  transition: 0.7s cubic-bezier(0.05, 0.6, 0.16, 1.03);
}
#top_right ul {
  transform: translate(0, 0);
  transition: 3s cubic-bezier(0.05, 0.45, 0.21, 1);
  transition-delay: 0.5s;
}
#top_right li {
  filter: grayscale(0);
}
#top_right li:nth-child(1) {
  transition-delay: 1.7s;
}
#top_right li:nth-child(2) {
  transition-delay: 1.9s;
}
#top_right li:nth-child(3) {
  transition-delay: 2.1s;
}
#top_right li:nth-child(4) {
  transition-delay: 2.3s;
}
#top_right li:nth-child(5) {
  transition-delay: 2.5s;
}
#top_right li:nth-child(6) {
  transition-delay: 2.7s;
}
#top_right li:nth-child(7) {
  transition-delay: 2.9s;
}
#top_right li:nth-child(8) {
  transition-delay: 3.1s;
}
#top_right li:nth-child(9) {
  transition-delay: 3.3s;
}
#top_right li:nth-child(10) {
  transition-delay: 3.5s;
}
#top_right li:nth-child(11) {
  transition-delay: 3.7s;
}
#top_right li:nth-child(12) {
  transition-delay: 3.9s;
}
#top_right li:nth-child(13) {
  transition-delay: 4.1s;
}
@media only screen and (max-width: 768px) {
  #top_right li:nth-child(1) {
    transition-delay: 0s;
  }
  #top_right li:nth-child(2) {
    transition-delay: 0.2s;
  }
  #top_right li:nth-child(3) {
    transition-delay: 0.4s;
  }
  #top_right li:nth-child(4) {
    transition-delay: 0.6s;
  }
  #top_right li:nth-child(5) {
    transition-delay: 0.8s;
  }
  #top_right li:nth-child(6) {
    transition-delay: 1s;
  }
  #top_right li:nth-child(7) {
    transition-delay: 1.2s;
  }
  #top_right li:nth-child(8) {
    transition-delay: 1.4s;
  }
  #top_right li:nth-child(9) {
    transition-delay: 1.6s;
  }
  #top_right li:nth-child(10) {
    transition-delay: 1.8s;
  }
  #top_right li:nth-child(11) {
    transition-delay: 2s;
  }
  #top_right li:nth-child(12) {
    transition-delay: 2.2s;
  }
  #top_right li:nth-child(13) {
    transition-delay: 2.4s;
  }
}
#top_right a {
  color: #fff;
  text-decoration: none;
  position: relative;
  display: block;
  overflow: hidden;
}
#top_right a:hover img {
  transform: scale(1.1);
}
#top_right p {
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-family: "Sawarabi Mincho";
  font-size: 14px;
  letter-spacing: 2px;
}
@media only screen and (max-width: 768px) {
  #top_right p {
    font-size: 5vw;
    bottom: 10px;
    right: 10px;
    line-height: 1;
  }
}

#top main.in:after {
  transition-delay: 0.3s;
}
#top.lower main.in:after {
  transition-delay: 0s;
}

.loaded main:after {
  transition-delay: 0s;
}
.loaded #top_right li:nth-child(1) {
  transition-delay: 0.0s;
}
.loaded #top_right li:nth-child(2) {
  transition-delay: 0.1s;
}
.loaded #top_right li:nth-child(3) {
  transition-delay: 0.2s;
}
.loaded #top_right li:nth-child(4) {
  transition-delay: 0.3s;
}
.loaded #top_right li:nth-child(5) {
  transition-delay: 0.4s;
}
.loaded #top_right li:nth-child(6) {
  transition-delay: 0.5s;
}
.loaded #top_right li:nth-child(7) {
  transition-delay: 0.6s;
}
.loaded #top_right li:nth-child(8) {
  transition-delay: 0.7s;
}
.loaded #top_right li:nth-child(9) {
  transition-delay: 0.8s;
}
.loaded #top_right li:nth-child(10) {
  transition-delay: 0.9s;
}
.loaded #top_right li:nth-child(11) {
  transition-delay: 1.0s;
}
.loaded #top_right li:nth-child(12) {
  transition-delay: 1.1s;
}
.loaded #top_right li:nth-child(13) {
  transition-delay: 1.2s;
}

#about {
  position: relative;
}
@media only screen and (max-width: 768px) {
  #about:after {
    animation: 2s about_anime 0s;
    content: "About";
    position: absolute;
    left: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    font-style: italic;
    top: 140px;
    font-size: 20vw;
    transform: rotate(90deg) translate(0, 57%);
    z-index: 2;
  }
}
#about section .inner {
  overflow: hidden;
}

.about_first {
  display: table;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .about_first {
    display: block;
    background: url(../img/common/bg.jpg) #d9cbaf;
    padding: 80px 20px 0;
  }
}
.about_first div {
  display: table-cell;
  vertical-align: top;
  width: 48%;
}
@media only screen and (max-width: 768px) {
  .about_first div {
    display: block;
  }
}
.about_first .about_photo {
  box-sizing: content-box;
  padding-right: 6%;
  overflow: hidden;
}
.about_first .about_photo img {
  width: 100%;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .about_first .about_photo {
    width: 65%;
  }
}
.about_first .about_prof {
  padding-top: 8vw;
}
@media only screen and (max-width: 768px) {
  .about_first .about_prof {
    width: 100%;
    text-align: right;
    padding-top: 0;
    margin-top: 3vw;
    padding-bottom: 40px;
  }
}
.about_first .about_name {
  font-size: 36px;
  font-size: 3.51563vw;
  margin-bottom: 0vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  line-height: 1.4;
}
@media only screen and (max-width: 768px) {
  .about_first .about_name {
    display: block;
    font-size: 7vw;
    margin-bottom: 3vw;
  }
}
.about_first .about_name span {
  font-size: 16px;
  font-size: 1.5625vw;
}
@media only screen and (max-width: 768px) {
  .about_first .about_name span {
    display: block;
    font-size: 4vw;
  }
}
.about_first .about_jobs {
  font-size: 12px;
  font-size: 1.17188vw;
}
@media only screen and (max-width: 768px) {
  .about_first .about_jobs {
    font-size: 4vw;
  }
}
.about_activity_right {
  margin-top: -38.7vw;
  float: right;
  width: 50.05%;
}
@media only screen and (max-width: 768px) {
  .about_activity_right {
    margin-top: 0;
    float: none;
    width: 100%;
    padding: 40px 20px 0;
  }
}
.about_activity_right img {
  border-radius: 10px;
  width: 100%;
}
.about_activity_right p {
  margin-bottom: 60px;
}
.about_activity_right p.about_frote {
  margin-bottom: 10vw;
}
@media only screen and (max-width: 768px) {
  .about_activity_right p.about_photo_sub {
    margin-bottom: 40px;
  }
  .about_activity_right p.about_photo_sub + .about_photo_sub {
    display: none;
  }
}
.about_activity_left {
  margin-top: 8vw;
  float: left;
  width: 44%;
}
@media only screen and (max-width: 768px) {
  .about_activity_left {
    float: none;
    width: 100%;
    padding: 0px 20px 0;
  }
  .about_activity_left p {
    margin-bottom: 15px;
  }
}
.about_activity_left dl {
  display: table;
}
@media only screen and (max-width: 768px) {
  .about_activity_left dl {
    margin-bottom: 15px;
  }
}
.about_activity_left dl dt {
  display: table-cell;
  width: 5em;
  position: relative;
  font-size: 1.36719vw;
}
@media only screen and (max-width: 768px) {
  .about_activity_left dl dt {
    font-size: 4.2vw;
    width: 4.2em;
  }
}
.about_activity_left dl dt:after {
  content: '';
  font-size: 1.36719vw;
  width: 0.5em;
  height: 1px;
  background: #000;
  right: 10px;
  top: 0.9em;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .about_activity_left dl dt:after {
    display: none;
  }
}
.about_activity_left dl dd {
  display: table-cell;
}

#shop {
  position: relative;
}
#shop footer .inner {
  margin-top: 0;
}
#shop .nav_logo {
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  #shop:after {
    animation: 2s shop_anime 0s;
    content: "Shop";
    position: absolute;
    left: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    font-style: italic;
    top: 140px;
    font-size: 20vw;
    transform: rotate(90deg) translate(0, 44%);
    z-index: 2;
  }
}
#shop section .inner {
  overflow: hidden;
}
#shop .shop-single footer .inner {
  border-top: none;
}
#shop .pagenation {
  clear: both;
  border-bottom: none;
  padding-top: 60px;
  padding-bottom: 20px;
  margin: 0 20px;
  overflow: hidden;
}
#shop .pagenation .prev {
  float: left;
  display: block;
}
#shop .pagenation .next {
  float: right;
  display: block;
}

.shop_first {
  display: table;
  width: 100%;
  padding-bottom: 70px;
  margin-bottom: 50px;
  border-bottom: 1px dotted #977;
}
@media only screen and (max-width: 768px) {
  .shop_first {
    display: block;
    background: url(../img/common/bg.jpg) #d9cbaf;
    padding: 80px 20px 30px;
    margin-bottom: 25px;
  }
}
.shop_photo {
  display: table-cell;
  width: 47%;
  padding-right: 3%;
  vertical-align: top;
}
.shop_photo img {
  width: 100%;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .shop_photo img {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .shop_photo {
    display: block;
    width: 100%;
  }
}
.shop_text {
  display: table-cell;
  padding-left: 3%;
  vertical-align: middle;
  width: 47%;
}
@media only screen and (max-width: 768px) {
  .shop_text {
    display: block;
    width: 100%;
  }
}
.shop_text h2 {
  font-size: 2vw;
  margin-bottom: 15px;
}
@media only screen and (max-width: 768px) {
  .shop_text h2 {
    font-size: 5.2vw;
    margin-bottom: 5px;
  }
}
.shop_list h4 {
  text-align: center;
  font-size: 1.6vw;
  margin-bottom: 0px;
  letter-spacing: 0.1rem;
}
@media only screen and (max-width: 768px) {
  .shop_list h4 {
    font-size: 4.5vw;
  }
}
.shop_list small {
  letter-spacing: 0.05rem;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 1vw;
  margin-bottom: 80px;
}
@media only screen and (max-width: 768px) {
  .shop_list small {
    font-size: 2.5vw;
    margin-bottom: 50px;
  }
}
.shop_list ul {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .shop_list ul {
    margin: 0 20px;
  }
}
.shop_list li {
  width: 33.3%;
  text-align: center;
  margin-bottom: 70px;
  position: relative;
  font-size: 14px;
  font-size: 1.36719vw;
}
.shop_list li.new:after {
  content: '';
  position: absolute;
  width: 71px;
  height: 30px;
  background: url(../img/common/new.png);
  top: -40px;
  left: 50px;
}
@media only screen and (max-width: 768px) {
  .shop_list li.new:after {
    background-size: cover;
    width: 50px;
    height: 20px;
    top: -8vw;
    left: -5vw;
  }
}
@media only screen and (max-width: 768px) {
  .shop_list li {
    width: 46.5%;
    margin-right: 7%;
    margin-bottom: 50px;
  }
  .shop_list li:nth-child(2n) {
    margin-right: 0;
  }
}
.shop_list a {
  text-decoration: none;
  display: block;
  margin: 0 60px 20px;
}
@media only screen and (max-width: 768px) {
  .shop_list a {
    margin: 0 0 20px;
  }
}
.shop_list_img {
  display: table-cell;
  vertical-align: bottom;
  text-align: center;
  margin: 0 60px 20px;
}
.shop_list_img img {
  transition: filter 0.2s ,transform 0.3s ease-out;
}
.shop_list_img:hover img {
  transform: translate(-5px, -5px);
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.35));
  -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.35));
}
@media only screen and (max-width: 768px) {
  .shop_list_img {
    min-height: auto;
    margin: 0 0 20px;
  }
}
.shop_list_text h3 {
  line-height: 1.6;
  font-size: 17px;
  font-size: 1.66016vw;
}
@media only screen and (max-width: 768px) {
  .shop_list_text h3 {
    font-size: 4vw;
  }
}
.shop_list_text small {
  font-size: 10px;
  font-size: 0.97656vw;
  display: block;
  margin-bottom: 12px;
}
@media only screen and (max-width: 768px) {
  .shop_list_text small {
    font-size: 3vw;
  }
}
.shop_list_price {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  font-size: 15px;
  font-size: 1.46484vw;
}
@media only screen and (max-width: 768px) {
  .shop_list_price {
    font-size: 4.5vw;
  }
}

#blog {
  position: relative;
}
#blog footer .inner {
  border-top: none;
  margin-top: 0;
}
#blog .nav_logo {
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  #blog main {
    padding-bottom: 0;
  }
  #blog:after {
    animation: 2s blog_anime 0s;
    content: "Blog";
    position: absolute;
    left: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    font-style: italic;
    top: 175px;
    font-size: 20vw;
    transform: rotate(90deg) translate(0, 37%);
    z-index: 2;
  }
}
#blog section .inner {
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  #blog.single:after {
    top: 60vw;
    opacity: 0.3;
  }
}

.blog_list {
  position: relative;
  padding-top: 2vw;
  padding-bottom: 110px;
  transition: 0.5s;
}
@media only screen and (max-width: 768px) {
  .blog_list {
    padding-top: 42vw;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 520px) {
  .blog_list {
    padding-top: 50vw;
  }
}
@media only screen and (max-width: 390px) {
  .blog_list {
    padding-top: 58vw;
  }
}
.blog_list h3 {
  margin: 0 30px;
  text-align: right;
  position: absolute;
  top: 10px;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .blog_list h3 {
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    background: url(../img/common/bg.jpg) #d9cbaf;
    padding-top: 75px;
    padding-right: 16px;
    padding-left: 35%;
    padding: 75px 16px 25px 40%;
  }
}
.blog_list ul {
  margin: 0 30px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .blog_list ul {
    margin: 0 20px;
  }
}
.blog_list li {
  width: 44%;
  text-align: center;
  margin-bottom: 8vw;
  margin-right: 12%;
  position: relative;
  font-size: 14px;
  font-size: 1.36719vw;
}
.blog_list li:nth-child(2n) {
  margin-right: 0;
  top: 110px;
}
@media only screen and (max-width: 768px) {
  .blog_list li {
    width: 100%;
    font-size: 4.4vw;
    line-height: 1.6;
    border-bottom: 1px dotted #977;
    margin-right: 0;
    margin-bottom: 50px;
    padding-bottom: 40px;
  }
  .blog_list li:nth-child(2n) {
    margin-right: 0;
    top: 0;
  }
  .blog_list li:last-child {
    margin-bottom: 30px;
  }
}
.blog_list a {
  display: block;
  color: #1b1922;
  text-decoration: none;
  position: relative;
}
.blog_list a:after {
  content: '';
  width: 0vw;
  height: 0vw;
  border-top: 1px solid #1b1922;
  border-left: 1px solid #1b1922;
  position: absolute;
  top: -2vw;
  left: -2vw;
  transition: 0.3s;
}
.blog_list a:before {
  content: '';
  width: 0vw;
  height: 0vw;
  border-bottom: 1px solid #1b1922;
  border-right: 1px solid #1b1922;
  position: absolute;
  bottom: -1vw;
  right: -1vw;
  transition: 0.3s;
}
.blog_list a:hover img {
  transform: scale(1.07);
}
.blog_list a:hover:after {
  top: -2vw;
  left: -2vw;
  width: 6vw;
  height: 6vw;
}
.blog_list a:hover:before {
  width: 6vw;
  height: 6vw;
  bottom: -1vw;
  right: -1vw;
}
.blog_list small {
  font-size: 24px;
  font-size: 2.34375vw;
  display: block;
  margin-bottom: 12px;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 100;
  position: absolute;
  top: -0.5em;
  right: -30px;
  line-height: 1;
  z-index: 3;
}
@media only screen and (max-width: 768px) {
  .blog_list small {
    font-size: 8vw;
    right: -0.7em;
  }
}
.blog_list_img {
  display: block;
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;
}
.blog_list_img img {
  width: 100%;
  transition: 0.5s cubic-bezier(0.63, 0.37, 0.26, 0.99);
}
@media only screen and (max-width: 768px) {
  .blog_list_img {
    margin: 0 0 20px;
  }
}
.blog_list_text {
  text-align: left;
}
.blog_list_text h2 {
  line-height: 1.6;
  font-size: 20px;
  font-size: 1.95313vw;
  margin-bottom: 15px;
}
@media only screen and (max-width: 768px) {
  .blog_list_text h2 {
    font-size: 5.5vw;
    margin-bottom: 10px;
  }
}
.blog_wrap {
  background: rgba(255, 255, 255, 0.7);
  padding: 60px;
}
@media only screen and (max-width: 768px) {
  .blog_wrap {
    padding: 40px 20px 10px;
    margin-top: 65px;
  }
}
.blog_wrap .category_list {
  margin: 0 0 30px;
}
.blog_visual h1 {
  font-size: 30px;
  font-size: 2.92969vw;
  line-height: 1.6;
  margin-bottom: 50px;
}
@media only screen and (max-width: 768px) {
  .blog_visual h1 {
    font-size: 5.5vw;
    margin-bottom: 15px;
  }
}
.blog_visual img {
  margin-bottom: 4vw;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .blog_visual img {
    margin-bottom: 20px;
  }
}
.blog_contents p {
  margin-bottom: 1em;
}
.blog_contents img {
  margin: 0.7em 0;
}
.blog_data {
  overflow: hidden;
  padding-top: 80px;
}
@media only screen and (max-width: 768px) {
  .blog_data {
    padding-top: 40px;
  }
}
.blog_data ul {
  float: left;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .blog_data ul {
    width: 70%;
  }
}
.blog_data li {
  overflow: hidden;
  border-radius: 5px;
  float: left;
  margin-right: 15px;
}
@media only screen and (max-width: 768px) {
  .blog_data li {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.blog_data a {
  font-size: 14px;
  padding: 2px 15px;
  background: #e9dec4;
  text-decoration: none;
  display: block;
}
@media only screen and (max-width: 768px) {
  .blog_data a {
    font-size: 3.5vw;
  }
}
.blog_data p {
  display: block;
  float: right;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 100;
  line-height: 2;
}
@media only screen and (max-width: 768px) {
  .blog_data p {
    font-size: 4vw;
    width: 30%;
    text-align: right;
  }
}
.blog_pagenation {
  margin: 40px 0 50px;
  padding: 40px 0;
  border-top: 1px dotted #977;
  border-bottom: 1px dotted #977;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .blog_pagenation {
    margin: 30px 0 30px;
  }
}
.blog_pagenation .single {
  border-bottom: none;
}
.blog_pagenation .sns {
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 768px) {
  .blog_pagenation .sns {
    width: 60%;
  }
  .blog_pagenation .sns li {
    margin: 0 5px;
  }
}

#works {
  position: relative;
}
#works footer .inner {
  margin-top: 0;
}
#works .nav_logo {
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  #works main {
    padding-bottom: 0;
  }
  #works:after {
    animation: 2s works_anime 0s;
    content: "Works";
    position: absolute;
    left: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    font-style: italic;
    top: 140px;
    font-size: 20vw;
    transform: rotate(90deg) translate(0, 54%);
    z-index: 2;
  }
}
#works .pagenation {
  clear: both;
  border-bottom: none;
  padding-top: 60px;
  padding-bottom: 20px;
  margin: 0 20px;
  overflow: hidden;
}
#works .pagenation .prev {
  float: left;
  display: block;
}
#works .pagenation .next {
  float: right;
  display: block;
}

.works_list {
  transition: 0.5s;
}
@media only screen and (max-width: 768px) {
  .works_list {
    padding-top: 42vw;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 520px) {
  .works_list {
    padding-top: 50vw;
  }
}
@media only screen and (max-width: 390px) {
  .works_list {
    padding-top: 52vw;
  }
}
.works_list h3 {
  font-size: 36px;
  font-size: 3.51563vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 1.6;
  margin: 20px 0 90px;
}
@media only screen and (max-width: 768px) {
  .works_list h3 {
    letter-spacing: 0.15em;
    position: absolute;
    font-size: 5vw;
    text-align: right;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    background: url(../img/common/bg.jpg) #d9cbaf;
    padding-top: 75px;
    padding-right: 16px;
    padding-left: 35%;
    padding: 75px 16px 25px 40%;
  }
}
.works_list ul {
  margin: 0 20px;
}
.works_list li {
  margin-bottom: 7vw;
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .works_list li {
    margin-bottom: 60px;
  }
}
.works_list li.a_left {
  text-align: left;
}
.works_list li.a_left .works_list_img {
  margin-left: 5%;
  padding-right: 0;
  float: right;
}
@media only screen and (max-width: 768px) {
  .works_list li.a_left .works_list_img {
    padding: 0;
  }
}
.works_list li.a_left .works_list_text {
  right: auto;
  left: 45px;
}
@media only screen and (max-width: 768px) {
  .works_list li.a_left .works_list_text {
    right: auto;
    left: 0;
    clear: both;
  }
}
@media only screen and (min-width: 768px) {
  .works_list li.a_left a:hover .works_list_text {
    transform: translate(-50%, -50%);
    left: 22%;
    right: auto;
  }
}
.works_list li.a_left a:after {
  right: auto;
  left: 15px;
}
@media only screen and (max-width: 768px) {
  .works_list li.a_left a:after {
    right: 15px;
    left: auto;
  }
}
.works_list a {
  display: block;
  text-decoration: none;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.works_list a:before {
  content: '';
  background: #987;
  position: absolute;
  height: 1px;
  bottom: 0;
  right: 5px;
  left: 5px;
  z-index: 2;
  transition: 0s;
}
@media only screen and (min-width: 768px) {
  .works_list a:hover .works_list_text {
    transform: translate(50%, -50%);
    right: 22%;
  }
  .works_list a:hover .works_list_text:before {
    width: 2vw;
    height: 2vw;
  }
  .works_list a:hover .works_list_text:after {
    width: 2vw;
    height: 2vw;
  }
  .works_list a:hover .works_list_img img {
    transform: scale(1.1);
  }
}
@media only screen and (max-width: 768px) {
  .works_list a {
    display: block;
  }
}
.works_list a:after {
  content: 'more';
  position: absolute;
  font-size: 28px;
  font-size: 2.73438vw;
  bottom: 0;
  right: 15px;
  line-height: 0.7;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  font-style: italic;
  z-index: 3;
}
@media only screen and (max-width: 768px) {
  .works_list a:after {
    right: 15px;
    left: auto;
    font-size: 7vw;
  }
}
.works_list_img {
  display: block;
  float: left;
  vertical-align: middle;
  width: 54%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .works_list_img {
    display: block;
    width: 100%;
    padding-right: 0;
    float: none;
    margin-bottom: 18px;
  }
}
.works_list_img img {
  width: 100%;
}
.works_list_text {
  display: block;
  vertical-align: middle;
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}
.works_list_text:before {
  content: '';
  width: 0vw;
  height: 0vw;
  border-top: 1px solid #1b1922;
  border-left: 1px solid #1b1922;
  position: absolute;
  top: -9px;
  left: -20px;
  transition-duration: 0.2s;
  transition-delay: 0.25s;
}
.works_list_text:after {
  transition-duration: 0.2s;
  transition-delay: 0.25s;
  content: '';
  width: 0vw;
  height: 0vw;
  border-bottom: 1px solid #1b1922;
  border-right: 1px solid #1b1922;
  position: absolute;
  bottom: -10px;
  right: -21px;
}
@media only screen and (max-width: 768px) {
  .works_list_text {
    display: block;
    position: relative;
    top: 0;
    transform: translate(0, 0);
    right: 0;
  }
}
.works_list_text h2 {
  font-size: 18px;
  font-size: 1.75781vw;
}
@media only screen and (max-width: 768px) {
  .works_list_text h2 {
    font-size: 5.5vw;
    line-height: 1.6;
  }
}
.works_list_text small {
  font-size: 12px;
  font-size: 1.17188vw;
}
@media only screen and (max-width: 768px) {
  .works_list_text small {
    font-size: 3.5vw;
    margin-bottom: 25px;
    display: block;
  }
}
.works_visual {
  margin: 10px 30px 60px;
  transition: 0.5s;
  border-radius: 10px;
  overflow: hidden;
}
.works_visual h3 {
  display: none;
}
@media only screen and (max-width: 768px) {
  .works_visual {
    margin: 0;
    padding: 34vw 20px 0;
  }
  .works_visual h3 {
    display: block;
    letter-spacing: 0.15em;
    position: absolute;
    font-size: 5vw;
    text-align: right;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    background: url(../img/common/bg.jpg) #d9cbaf;
    padding-top: 75px;
    padding-right: 16px;
    padding-left: 35%;
    padding: 75px 16px 25px 40%;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
}
@media only screen and (max-width: 520px) {
  .works_visual {
    padding-top: 38vw;
  }
}
@media only screen and (max-width: 390px) {
  .works_visual {
    padding-top: 49vw;
  }
}
.works_visual img {
  width: 100%;
}
.works_contents {
  margin: 0 30px;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .works_contents {
    margin: 0 20px;
    min-height: inherit;
  }
}
.works_contents h1 {
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-size: 2.34375vw;
  line-height: 1.6;
  margin-bottom: 10px;
}
@media only screen and (max-width: 768px) {
  .works_contents h1 {
    padding-left: 0;
    font-size: 6vw;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
.works_contents p {
  width: 100%;
  text-align: left;
  margin-bottom: 2vw;
}
@media only screen and (max-width: 768px) {
  .works_contents p {
    padding-left: 0;
    text-align: left;
    margin-bottom: 15px;
  }
}
.works_morecontents {
  margin: 50px 30px 55px;
}
@media only screen and (max-width: 768px) {
  .works_morecontents {
    margin: 0px 20px 0px;
  }
}
.works_morecontents p {
  margin-bottom: 2em;
}
.works_morecontents hr {
  border-top: 1px dotted rgba(0, 0, 0, 0.5);
  margin-bottom: 2em;
}
.works_specs {
  overflow: hidden;
  padding: 15px;
  margin: 40px;
  border: 1px dotted #555;
}
@media only screen and (max-width: 768px) {
  .works_specs {
    margin: 25px;
  }
}
.works_photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .works_photo {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.works_photo img {
  width: 100%;
}
.works_photo.photo02 {
  top: 36.5vw;
}
@media only screen and (max-width: 768px) {
  .works_photo.photo02 {
    top: 0;
  }
}
.works_price {
  text-align: left;
  overflow: hidden;
  border-top: 1px dotted #977;
  border-bottom: 1px dotted #977;
  padding: 20px 0 0px 0;
}
@media only screen and (max-width: 768px) {
  .works_price {
    padding: 20px 0;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
.works_price p {
  padding-left: 0;
}
@media only screen and (max-width: 768px) {
  .works_price p {
    margin-bottom: 0;
  }
}
.works_price p:first-child {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  font-size: 19px;
  font-size: 1.85547vw;
  margin-bottom: 15px;
  line-height: 2;
  width: 50%;
  float: left;
}
@media only screen and (max-width: 768px) {
  .works_price p:first-child {
    font-size: 6vw;
    margin-bottom: 0;
    line-height: 2.4;
  }
}
.works_price .btn_cart {
  float: right;
  width: 50%;
}
.works_spec {
  padding-left: 30px;
}
@media only screen and (max-width: 768px) {
  .works_spec {
    padding: 0 20px;
    float: none;
  }
}
.works_box {
  width: 50%;
  float: left;
}
@media only screen and (max-width: 768px) {
  .works_box {
    width: 100%;
    float: none;
  }
}
.works_box:first-child {
  width: 45%;
  margin-right: 5%;
}
@media only screen and (max-width: 768px) {
  .works_box:first-child {
    width: 100%;
    margin-right: 0;
  }
}

.itemset {
  display: table;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .itemset {
    display: block;
  }
}
.itemset .itemimg {
  display: table-cell;
  vertical-align: top;
  width: 50%;
  padding-right: 5%;
}
@media only screen and (max-width: 768px) {
  .itemset .itemimg {
    display: block;
    width: 100%;
    padding-right: 0;
  }
}
.itemset .itemimg p {
  display: block;
  overflow: hidden;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .itemset .itemimg p {
    margin-bottom: 1em;
  }
}
.itemset .itemimg img {
  margin: 0;
}
.itemset .itemtext {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .itemset .itemtext {
    display: block;
    width: 100%;
    padding-right: 0;
  }
}


